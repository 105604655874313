import React from 'react';
import classNames from 'classnames';
import { useConfiguration } from '../../context/configurationContext';
import { Button } from '../../components';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

import css from './SearchPage.module.css';
import { isMarketplaceSecondLife } from '../../util/marketplace';

const ViewSelector = props => {
  const { className, selectedView, onSelect, onMobileMapClick } = props;

  const config = useConfiguration();
  const intl = useIntl();

  const { viewSelector } = config.search;

  const classes = classNames(css.viewSelector, className);

  const history = useHistory();
  const currentPath = history?.location?.pathname;

  return (
    <div className={classes}>
      {viewSelector.options.map(option => {
        const isSellAsIs = currentPath.includes('sell-as-is');
        const isMapOption = option.key === 'map';
        const is2ndLife = isMarketplaceSecondLife(config);

        if (is2ndLife && !isSellAsIs && isMapOption) {
          return <React.Fragment key={option.key} />;
        }

        return (
          <Button
            key={option.key}
            className={classNames(css.viewButton, {
              [css.viewSelected]: selectedView === option.key,
            })}
            type="button"
            onClick={() => {
              if (isSellAsIs && isMapOption) {
                onMobileMapClick();
              }
              onSelect({ [viewSelector.queryParamName]: option.key });
            }}
          >
            {intl.formatMessage({ id: option.label })}
          </Button>
        );
      })}
    </div>
  );
};

export default ViewSelector;
