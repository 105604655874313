import React, { Component, useMemo } from 'react';
import { array, arrayOf, bool, func, object, oneOf, shape, string } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory, useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import camelCase from 'lodash/camelCase';
import union from 'lodash/union';
import classNames from 'classnames';

import { useIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import {
  isAnyFilterActive,
  isMainSearchTypeKeywords,
  isOriginInUse,
  getQueryParamNames,
  SEARCH_PAGE_COMPANY_VIEW_PARAM,
  getFilteredArchivedListings,
  getFilteredSellAsIsListings,
} from '../../util/search';
import { parse } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';

import {
  ButtonTabNavHorizontal,
  H1,
  H3,
  H5,
  ModalInMobile,
  Page,
  ResponsiveBackgroundImageContainer,
} from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';

import { setActiveListing } from './SearchPage.duck';
import {
  groupListingFieldConfigs,
  initialValues,
  searchParamsPicker,
  validUrlQueryParamsFromProps,
  validFilterParams,
  cleanSearchFromConflictingParams,
  createSearchResultSchema,
} from './SearchPage.shared';

import FilterComponent from './FilterComponent';
import SearchMap from './SearchMap/SearchMap';
import MainPanelHeader from './MainPanelHeader/MainPanelHeader';
import SearchFiltersSecondary from './SearchFiltersSecondary/SearchFiltersSecondary';
import SearchFiltersPrimary from './SearchFiltersPrimary/SearchFiltersPrimary';
import SearchFiltersMobile from './SearchFiltersMobile/SearchFiltersMobile';
import SortBy from './SortBy/SortBy';
import SearchResultsPanel from './SearchResultsPanel/SearchResultsPanel';
import NoSearchResultsMaybe from './NoSearchResultsMaybe/NoSearchResultsMaybe';
import ViewSelector from './ViewSelector';
import { getSuboptionsForOption } from '../../ducks/dimr.duck';
import { COUNTRIES_BY_LOCALE, LOCALE, getCurrentLocale } from '../../util/locale';
import {
  ARCHIVED_TABS,
  LISTING_CONDITION,
  LISTING_STATUS,
  PENDING_INTAKE_TABS,
  PENDING_REVIEW_TABS,
  SELL_AS_IS_TABS,
} from '../../constants/listing';
import {
  getMarketplaceId,
  isMarketplaceRespare,
  isMarketplaceSecondLife,
  MARKETPLACE_ID,
  SEARCH_PAGE_TYPE,
} from '../../util/marketplace';
import {
  getListingLocalesForUserRole,
  getListingStatusForUserRole,
  isUserVerifiedCompanyDealer,
} from '../../util/user';

import css from './SearchPage.module.css';
import {
  getArchivedTabs,
  getForSaleTabs,
  getPendingIntakeTabs,
  getPendingReviewTabs,
  getSellAsIsTabs,
} from '../../util/tabConfigs';

const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

// Primary filters have their content in dropdown-popup.
// With this offset we move the dropdown to the left a few pixels on desktop layout.
const FILTER_DROPDOWN_OFFSET = -14;
export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchMapOpenOnMobile: props.tab === 'map',
      isMobileModalOpen: false,
      currentQueryParams: validUrlQueryParamsFromProps(props),
      isSecondaryFiltersOpen: false,
      selectedArchivedTab: ARCHIVED_TABS.BOUGHT,
      selectedPendingIntakeTab: PENDING_INTAKE_TABS.ALL,
      selectedPendingReviewTab: PENDING_REVIEW_TABS.ALL,
      selectedForSaleTab: LISTING_STATUS.PUBLISHED,
      selectedSellAsIsTab: SELL_AS_IS_TABS.FOR_SALE,
    };

    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);

    // Filter functions
    this.applyFilters = this.applyFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);

    // SortBy
    this.handleSortBy = this.handleSortBy.bind(this);
  }

  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter } = data;

    const routes = this.props.routeConfiguration;
    const searchPagePath = pathByRouteName(this.props.currentPage, routes);
    const currentPath =
      typeof window !== 'undefined' && window.location && window.location.pathname;

    const currentPathWithoutLang = currentPath?.replace(`/${this.props.interfaceLang}`, '');

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPathWithoutLang === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage) {
      const { history, location, config } = this.props;
      const { listingFields: listingFieldsConfig } = config?.listing || {};
      const { defaultFilters: defaultFiltersConfig } = config?.search || {};

      // parse query parameters, including a custom attribute named category
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      const originMaybe = isOriginInUse(this.props.config) ? { origin: viewportCenter } : {};
      const dropNonFilterParams = false;

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: true,
        ...validFilterParams(rest, listingFieldsConfig, defaultFiltersConfig, dropNonFilterParams),
      };

      history.push(createResourceLocatorString(this.props.currentPage, routes, {}, searchParams));
    }
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  // Apply the filters by redirecting to SearchPage with new filters.
  applyFilters() {
    const { history, routeConfiguration, config, currentPage } = this.props;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const searchParams = { ...urlQueryParams, ...this.state.currentQueryParams };
    const search = cleanSearchFromConflictingParams(
      searchParams,
      listingFieldsConfig,
      defaultFiltersConfig,
      sortConfig
    );

    history.push(createResourceLocatorString(currentPage, routeConfiguration, {}, search));
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    this.setState({ currentQueryParams: {} });
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { history, routeConfiguration, config, currentPage } = this.props;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig } = config?.search || {};

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);
    const filterQueryParamNames = getQueryParamNames(listingFieldsConfig, defaultFiltersConfig);

    // Reset state
    this.setState({ currentQueryParams: {} });

    // Reset routing params
    const queryParams = omit(urlQueryParams, filterQueryParamNames);
    history.push(createResourceLocatorString(currentPage, routeConfiguration, {}, queryParams));
  }

  getHandleChangedValueFn(useHistoryPush) {
    const { history, routeConfiguration, config, currentPage } = this.props;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    return updatedURLParams => {
      const updater = prevState => {
        const { address, bounds, keywords } = urlQueryParams;
        const mergedQueryParams = { ...urlQueryParams, ...prevState.currentQueryParams };

        // If we are changing the view, and the view is different from 'map'.
        const shouldRemoveLocation = updatedURLParams.view && updatedURLParams.view !== 'map';

        // Address and bounds are handled outside of MainPanel.
        // I.e. TopbarSearchForm && search by moving the map.
        // We should always trust urlQueryParams with those.
        // The same applies to keywords, if the main search type is keyword search.
        const keywordsMaybe = isMainSearchTypeKeywords(config) ? { keywords } : {};
        return {
          currentQueryParams: {
            ...mergedQueryParams,
            ...updatedURLParams,
            ...keywordsMaybe,
            address: shouldRemoveLocation ? null : address,
            bounds: shouldRemoveLocation ? null : bounds,
          },
        };
      };

      const callback = () => {
        if (useHistoryPush) {
          const searchParams = this.state.currentQueryParams;
          const search = cleanSearchFromConflictingParams(
            searchParams,
            listingFieldsConfig,
            defaultFiltersConfig,
            sortConfig
          );
          history.replace(createResourceLocatorString(currentPage, routeConfiguration, {}, search));
        }
      };

      this.setState(updater, callback);
    };
  }

  handleSortBy(urlParam, values) {
    const { history, routeConfiguration, currentPage } = this.props;
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString(currentPage, routeConfiguration, {}, queryParams));
  }

  // Reset all filter query parameters
  handleResetAll = e => {
    this.resetAll(e);

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  };

  handleOptionSelect = () => {
    this.setState({
      currentQueryParams: {},
      selectedArchivedTab: ARCHIVED_TABS.BOUGHT,
      selectedPendingIntakeTab: PENDING_INTAKE_TABS.ALL,
      selectedPendingReviewTab: PENDING_REVIEW_TABS.ALL,
      selectedForSaleTab: LISTING_STATUS.PUBLISHED,
      selectedSellAsIsTab: SELL_AS_IS_TABS.FOR_SALE,
    });
  };

  render() {
    const {
      intl,
      listings,
      location,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      activeListingId,
      onActivateListing,
      routeConfiguration,
      config,
      isAuthenticated,
      authInfoLoaded,
      currentPage,
      queryCompanyInProgress,
      queryCompanyError,
      company,
      searchPageType,
      history,
      routes,
    } = this.props;

    const is2ndLife = isMarketplaceSecondLife(config);

    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};

    const activeListingTypes = config?.listing?.listingTypes.map(config => config.listingType);
    const marketplaceCurrency = config.currency;

    // Page transition might initially use values from previous search
    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.maps.search.sortSearchByDistance)
    const { searchParamsAreInSync, urlQueryParams, searchParamsInURL } = searchParamsPicker(
      location.search,
      searchParams,
      listingFieldsConfig,
      defaultFiltersConfig,
      sortConfig,
      isOriginInUse(config)
    );

    const isMyListingsSearch = searchPageType === SEARCH_PAGE_TYPE.MY_LISTINGS;

    const isArhivedFilterActive =
      isMyListingsSearch && decodeURIComponent(history.location.search).includes('archived');

    const isPendingIntakeFilterActive =
      isMyListingsSearch && decodeURIComponent(history.location.search).includes('pendingIntake');

    const isPendingReviewFilterActive =
      isMyListingsSearch &&
      decodeURIComponent(history.location.search).includes('pendingReview') &&
      !decodeURIComponent(history.location.search).includes('asIs');

    const isForSaleFilterActive =
      isMyListingsSearch &&
      decodeURIComponent(history.location.search).includes('forSale') &&
      (decodeURIComponent(history.location.search).includes('published') ||
        decodeURIComponent(history.location.search).includes('sold'));

    const isSellAsIsFilterActive =
      isMyListingsSearch && decodeURIComponent(history.location.search).includes('asIs');

    const mainPanelHeaderTitle =
      searchParamsInURL?.meta_status === 'pendingIntake' && searchParamsInURL?.pendingIntakeState
        ? intl.formatMessage({
            id: `SearchPage.${searchPageType || 'default'}.pendingIntake.${
              searchParamsInURL?.pendingIntakeState
            }.title`,
          })
        : isSellAsIsFilterActive
        ? intl.formatMessage({ id: `SearchPage.${searchPageType}.sellAsIs.title` })
        : searchParamsInURL?.meta_isBuyerConfirmPending
        ? intl.formatMessage({ id: `SearchPage.${searchPageType}.pendingSold.title` })
        : searchPageType === SEARCH_PAGE_TYPE.SELL_AS_IS
        ? intl.formatMessage({ id: 'SearchPage.default.sellAsIs.title' })
        : searchPageType === SEARCH_PAGE_TYPE.PUBLISHED
        ? intl.formatMessage({ id: 'SearchPage.default.published.title' })
        : isArhivedFilterActive
        ? intl.formatMessage({ id: `SearchPage.${searchPageType}.archived.title` })
        : searchPageType === SEARCH_PAGE_TYPE.MY_LISTINGS && searchParams?.meta_status
        ? intl.formatMessage({
            id: `SearchPage.${searchPageType || 'default'}.${searchParams?.meta_status}.title`,
          })
        : searchPageType === SEARCH_PAGE_TYPE.MY_LISTINGS &&
          intl.formatMessage({ id: 'SearchPage.myListings.title' });

    const mainPanelHeaderDescription =
      searchParamsInURL?.meta_status === 'pendingIntake' && searchParamsInURL?.pendingIntakeState
        ? intl.formatMessage({
            id: `SearchPage.${searchPageType || 'default'}.pendingIntake.${
              searchParamsInURL?.pendingIntakeState
            }.description`,
          })
        : searchParamsInURL?.meta_isBuyerConfirmPending
        ? intl.formatMessage({
            id: `SearchPage.${searchPageType}.pendingSold.description`,
          })
        : searchPageType === SEARCH_PAGE_TYPE.SELL_AS_IS
        ? intl.formatMessage({ id: 'SearchPage.default.sellAsIs.description' })
        : searchPageType === SEARCH_PAGE_TYPE.PUBLISHED
        ? intl.formatMessage({ id: 'SearchPage.default.published.description' })
        : isArhivedFilterActive
        ? intl.formatMessage({ id: `SearchPage.${searchPageType}.archived.description` })
        : searchPageType === SEARCH_PAGE_TYPE.MY_LISTINGS && searchParams?.meta_status
        ? intl.formatMessage({
            id: `SearchPage.${searchPageType || 'default'}.${searchParamsInURL?.meta_status || ''}${
              searchParamsInURL?.meta_status ? '.description' : 'description'
            }`,
          })
        : searchPageType === SEARCH_PAGE_TYPE.MY_LISTINGS &&
          intl.formatMessage({ id: 'SearchPage.myListings.description' });

    const { currentUser } = this.props;
    const locationSearch = parse(location.search);

    const archivedTabs = getArchivedTabs(
      this.state.selectedArchivedTab,
      this.setState.bind(this),
      history,
      routes
    );

    const pendingIntakeTabs = getPendingIntakeTabs(
      this.state.selectedPendingIntakeTab,
      locationSearch,
      this.setState.bind(this),
      history,
      routes
    );

    const pendingReviewTabs = getPendingReviewTabs(
      this.state.selectedPendingReviewTab,
      this.setState.bind(this),
      history,
      routes,
      currentUser
    );

    const forSaleTabs = getForSaleTabs(
      this.state.selectedForSaleTab,
      this.setState.bind(this),
      history,
      routes
    );

    const sellAsIsTabs = getSellAsIsTabs(
      this.state.selectedSellAsIsTab,
      locationSearch,
      this.setState.bind(this),
      history,
      routes
    );

    const filteredSellAsIsTabs =
      locationSearch?.sellAsIsState && locationSearch?.sellAsIsState === 'buying'
        ? sellAsIsTabs.filter(tab => tab.id !== 'sold')
        : locationSearch?.sellAsIsState && locationSearch.sellAsIsState === 'selling'
        ? sellAsIsTabs.filter(tab => tab.id !== 'bought')
        : sellAsIsTabs.filter(tab => tab.id !== 'sold');

    const validQueryParams = validFilterParams(
      searchParamsInURL,
      listingFieldsConfig,
      defaultFiltersConfig,
      false
    );

    const selectedView =
      validQueryParams.view ||
      (isMarketplaceRespare(config)
        ? 'map'
        : isMarketplaceSecondLife || !location.search.includes(SEARCH_PAGE_COMPANY_VIEW_PARAM)
        ? 'grid'
        : 'list');

    const isMapView = selectedView === 'map';
    const isGridView = selectedView === 'grid';
    const isListView = !isMapView && !isGridView;

    const isWindowDefined = typeof window !== 'undefined';
    const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
    const shouldShowSearchMap =
      !isMobileLayout || (isMobileLayout && this.state.isSearchMapOpenOnMobile);

    const isKeywordSearch = isMainSearchTypeKeywords(config);
    const defaultFilters = isKeywordSearch
      ? defaultFiltersConfig.filter(f => f.key !== 'keywords')
      : defaultFiltersConfig;
    const [customPrimaryFilters, customSecondaryFilters] = groupListingFieldConfigs(
      listingFieldsConfig,
      activeListingTypes
    );
    const availablePrimaryFilters = [...customPrimaryFilters, ...defaultFilters];
    const availableFilters = [
      ...customPrimaryFilters,
      ...defaultFilters,
      ...customSecondaryFilters,
    ];

    const hasSecondaryFilters = !!(customSecondaryFilters && customSecondaryFilters.length > 0);

    // Selected aka active filters
    const selectedFilters = validFilterParams(
      validQueryParams,
      listingFieldsConfig,
      defaultFiltersConfig
    );
    const keysOfSelectedFilters = Object.keys(selectedFilters);
    const selectedFiltersCountForMobile = isKeywordSearch
      ? keysOfSelectedFilters.filter(f => f !== 'keywords').length
      : keysOfSelectedFilters.length;
    const isValidDatesFilter =
      searchParamsInURL.dates == null ||
      (searchParamsInURL.dates != null && searchParamsInURL.dates === selectedFilters.dates);

    // Selected aka active secondary filters
    const selectedSecondaryFilters = hasSecondaryFilters
      ? validFilterParams(validQueryParams, customSecondaryFilters, [])
      : {};
    const selectedSecondaryFiltersCount = Object.keys(selectedSecondaryFilters).length;

    const isSecondaryFiltersOpen = !!hasSecondaryFilters && this.state.isSecondaryFiltersOpen;
    const propsForSecondaryFiltersToggle = hasSecondaryFilters
      ? {
          isSecondaryFiltersOpen: this.state.isSecondaryFiltersOpen,
          toggleSecondaryFiltersOpen: isOpen => {
            this.setState({ isSecondaryFiltersOpen: isOpen, currentQueryParams: {} });
          },
          selectedSecondaryFiltersCount,
        }
      : {};

    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const totalItems =
      searchParamsAreInSync && hasPaginationInfo
        ? pagination.totalItems
        : pagination?.paginationUnsupported
        ? listings.length
        : 0;
    const listingsAreLoaded =
      !searchInProgress &&
      searchParamsAreInSync &&
      !!(hasPaginationInfo || pagination?.paginationUnsupported);

    const conflictingFilterActive = isAnyFilterActive(
      sortConfig.conflictingFilters,
      validQueryParams,
      listingFieldsConfig,
      defaultFiltersConfig
    );
    const sortBy = mode => {
      return sortConfig.active ? (
        <SortBy
          sort={validQueryParams[sortConfig.queryParamName]}
          isConflictingFilterActive={!!conflictingFilterActive}
          hasConflictingFilters={!!(sortConfig.conflictingFilters?.length > 0)}
          selectedFilters={selectedFilters}
          onSelect={this.handleSortBy}
          showAsPopup
          mode={mode}
          contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
        />
      ) : null;
    };

    const filteredListings = isArhivedFilterActive
      ? getFilteredArchivedListings(listings, currentUser, this.state.selectedArchivedTab)
      : isSellAsIsFilterActive
      ? getFilteredSellAsIsListings(listings, currentUser, this.state.selectedSellAsIsTab)
      : listings;

    const noResultsInfo = (
      <NoSearchResultsMaybe
        listingsAreLoaded={listingsAreLoaded}
        totalItems={isArhivedFilterActive ? filteredListings?.length : totalItems}
        location={location}
        resetAll={this.resetAll}
      />
    );

    const { bounds, origin } = searchParamsInURL || {};
    const { title, description, schema } = createSearchResultSchema(
      listings,
      searchParamsInURL || {},
      intl,
      routeConfiguration,
      config
    );

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    const viewSelector =
      isMarketplaceRespare(config) || !location.search.includes(SEARCH_PAGE_COMPANY_VIEW_PARAM) ? (
        <ViewSelector
          selectedView={selectedView}
          onSelect={this.getHandleChangedValueFn(true)}
          onMobileMapClick={() => this.setState({ isSearchMapOpenOnMobile: true })}
          config={config}
        />
      ) : null;

    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={description}
        title={title}
        schema={schema}
      >
        <TopbarContainer
          className={topbarClasses}
          currentPage={currentPage}
          currentSearchParams={validQueryParams}
          company={company}
          queryCompanyInProgress={queryCompanyInProgress}
        />

        <div className={css.container}>
          {!isMapView && (
            <aside className={css.layoutWrapperFilterColumn} data-testid="filterColumnAside">
              <div className={css.filterColumnContent}>
                {availableFilters.map(item => {
                  return (
                    <FilterComponent
                      key={`SearchFiltersAside.${item.key}`}
                      idPrefix="SearchFiltersAside"
                      className={css.filter}
                      rootClassName={item.key.includes('Grading') ? 'grading' : null}
                      config={item}
                      is2ndLife={isMarketplaceSecondLife(config)}
                      marketplaceCurrency={marketplaceCurrency}
                      urlQueryParams={urlQueryParams}
                      initialValues={initialValues(this.props, this.state.currentQueryParams)}
                      getHandleChangedValueFn={this.getHandleChangedValueFn}
                      intl={intl}
                      liveEdit
                      showAsPopup={false}
                      isDesktop
                      onOptionSelect={this.handleOptionSelect}
                      searchPageType={searchPageType}
                    />
                  );
                })}
                <button className={css.resetAllButton} onClick={e => this.handleResetAll(e)}>
                  <FormattedMessage id={'SearchFiltersMobile.resetAll'} />
                </button>
              </div>
            </aside>
          )}
          <div className={css.searchResultContainer}>
            {authInfoLoaded && !isAuthenticated && (
              <ResponsiveBackgroundImageContainer
                className={css.topBanner}
                image={config.branding.searchPageImage}
                childrenWrapperClassName={css.topBannerText}
                sizes="100%"
              >
                <H1>
                  {is2ndLife
                    ? intl.formatMessage({ id: 'SearchPage.titleContracts' })
                    : intl.formatMessage({ id: 'SearchPage.title' })}
                </H1>
                {!is2ndLife && (
                  <H3 as="h2">{intl.formatMessage({ id: 'SearchPage.description' })}</H3>
                )}
              </ResponsiveBackgroundImageContainer>
            )}
            {isMarketplaceSecondLife(config) && company ? (
              <div className={css.companyBanner}>
                <H3 as="h2">
                  {`${company.name} ${intl
                    .formatMessage({ id: 'General.listings' })
                    .toLowerCase()}`}
                </H3>
              </div>
            ) : null}

            {queryCompanyError ? (
              <div className={classNames(css.companyBanner, css.error)}>
                {intl.formatMessage({ id: 'MembersAdminPage.companyFetchFailed' })}
              </div>
            ) : null}

            <SearchFiltersMobile
              className={css.searchFiltersMobileMap}
              urlQueryParams={validQueryParams}
              sortByComponent={sortBy('mobile')}
              listingsAreLoaded={listingsAreLoaded}
              resultsCount={isArhivedFilterActive ? filteredListings.length : totalItems}
              searchInProgress={searchInProgress}
              searchListingsError={searchListingsError}
              showAsModalMaxWidth={MODAL_BREAKPOINT}
              viewSelector={viewSelector}
              onManageDisableScrolling={onManageDisableScrolling}
              onOpenModal={this.onOpenMobileModal}
              onCloseModal={this.onCloseMobileModal}
              resetAll={this.resetAll}
              selectedFiltersCount={selectedFiltersCountForMobile}
              noResultsInfo={noResultsInfo}
              isMapVariant
              title={mainPanelHeaderTitle}
              description={mainPanelHeaderDescription}
            >
              {availableFilters.map(config => {
                return (
                  <FilterComponent
                    key={`SearchFiltersMobile.${config.key}`}
                    idPrefix="SearchFiltersMobile"
                    config={config}
                    marketplaceCurrency={marketplaceCurrency}
                    urlQueryParams={validQueryParams}
                    initialValues={initialValues(this.props, this.state.currentQueryParams)}
                    getHandleChangedValueFn={this.getHandleChangedValueFn}
                    intl={intl}
                    liveEdit
                    showAsPopup={false}
                  />
                );
              })}
            </SearchFiltersMobile>
            <MainPanelHeader
              className={css.mainPanelMapVariant}
              sortByComponent={sortBy('desktop')}
              isSortByActive={sortConfig.active}
              listingsAreLoaded={listingsAreLoaded}
              resultsCount={
                isArhivedFilterActive || isSellAsIsFilterActive
                  ? filteredListings.length
                  : totalItems
              }
              searchInProgress={searchInProgress}
              searchListingsError={searchListingsError}
              noResultsInfo={noResultsInfo}
              title={is2ndLife ? mainPanelHeaderTitle : null}
              description={is2ndLife ? mainPanelHeaderDescription : null}
            >
              <div className={css.primaryFiltersWrapper}>
                {isMarketplaceSecondLife(config) && isArhivedFilterActive && (
                  <ButtonTabNavHorizontal className={css.tabs} tabs={archivedTabs} />
                )}
                {isMarketplaceSecondLife(config) && isPendingIntakeFilterActive && (
                  <ButtonTabNavHorizontal className={css.tabs} tabs={pendingIntakeTabs} />
                )}
                {isMarketplaceSecondLife(config) &&
                  isUserVerifiedCompanyDealer(currentUser) &&
                  isPendingReviewFilterActive && (
                    <ButtonTabNavHorizontal className={css.tabs} tabs={pendingReviewTabs} />
                  )}
                {isMarketplaceSecondLife(config) && isForSaleFilterActive && (
                  <ButtonTabNavHorizontal className={css.tabs} tabs={forSaleTabs} />
                )}
                {isMarketplaceSecondLife(config) && isSellAsIsFilterActive && (
                  <ButtonTabNavHorizontal className={css.tabs} tabs={filteredSellAsIsTabs} />
                )}
                {isMapView && (
                  <SearchFiltersPrimary {...propsForSecondaryFiltersToggle}>
                    {availablePrimaryFilters.map(config => {
                      return (
                        <FilterComponent
                          key={`SearchFiltersPrimary.${config.key}`}
                          idPrefix="SearchFiltersPrimary"
                          config={config}
                          marketplaceCurrency={marketplaceCurrency}
                          urlQueryParams={validQueryParams}
                          initialValues={initialValues(this.props, this.state.currentQueryParams)}
                          getHandleChangedValueFn={this.getHandleChangedValueFn}
                          intl={intl}
                          showAsPopup
                          contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                        />
                      );
                    })}
                  </SearchFiltersPrimary>
                )}
                {viewSelector}
              </div>
            </MainPanelHeader>

            {isSecondaryFiltersOpen ? (
              <div className={classNames(css.searchFiltersPanel)}>
                <SearchFiltersSecondary
                  urlQueryParams={validQueryParams}
                  listingsAreLoaded={listingsAreLoaded}
                  applyFilters={this.applyFilters}
                  cancelFilters={this.cancelFilters}
                  resetAll={this.resetAll}
                  onClosePanel={() => this.setState({ isSecondaryFiltersOpen: false })}
                >
                  {customSecondaryFilters.map(config => {
                    return (
                      <FilterComponent
                        key={`SearchFiltersSecondary.${config.key}`}
                        idPrefix="SearchFiltersSecondary"
                        config={config}
                        marketplaceCurrency={marketplaceCurrency}
                        urlQueryParams={validQueryParams}
                        initialValues={initialValues(this.props, this.state.currentQueryParams)}
                        getHandleChangedValueFn={this.getHandleChangedValueFn}
                        intl={intl}
                        showAsPopup={false}
                      />
                    );
                  })}
                </SearchFiltersSecondary>
              </div>
            ) : (
              <div
                className={classNames(css.listingsForMapVariant, {
                  [css.newSearchInProgress]: !(listingsAreLoaded || searchListingsError),
                })}
              >
                {searchListingsError ? (
                  <H3 className={css.error}>
                    <FormattedMessage id="SearchPage.searchError" />
                  </H3>
                ) : null}
                {!isValidDatesFilter ? (
                  <H5>
                    <FormattedMessage id="SearchPage.invalidDatesFilter" />
                  </H5>
                ) : null}
                <SearchResultsPanel
                  className={css.searchListingsPanel}
                  listings={filteredListings}
                  pagination={listingsAreLoaded ? pagination : null}
                  search={parse(location.search)}
                  setActiveListing={onActivateListing}
                  isMapVariant
                  isListView={isListView}
                />
              </div>
            )}
          </div>
          {isMapView && (
            <ModalInMobile
              className={css.mapPanel}
              id={`${currentPage}.map`}
              isModalOpenOnMobile={this.state.isSearchMapOpenOnMobile}
              onClose={() => this.setState({ isSearchMapOpenOnMobile: false })}
              showAsModalMaxWidth={MODAL_BREAKPOINT}
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <div className={css.mapWrapper} data-testid="searchMapContainer">
                {shouldShowSearchMap ? (
                  <SearchMap
                    reusableContainerClassName={css.map}
                    activeListingId={activeListingId}
                    bounds={bounds}
                    center={origin}
                    isSearchMapOpenOnMobile={this.state.isSearchMapOpenOnMobile}
                    location={location}
                    listings={listings || []}
                    onMapMoveEnd={this.onMapMoveEnd}
                    onCloseAsModal={() => {
                      onManageDisableScrolling(`${currentPage}.map`, false);
                    }}
                    messages={intl.messages}
                  />
                ) : null}
              </div>
            </ModalInMobile>
          )}
        </div>
      </Page>
    );
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  activeListingId: null,
};

SearchPageComponent.propTypes = {
  listings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,

  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,
};

const EnhancedSearchPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const routes = useRouteConfiguration();

  const locale = getCurrentLocale();

  const marketplaceId = getMarketplaceId(config);

  const {
    searchParams,
    dimr,
    currentUser,
    searchPageType = SEARCH_PAGE_TYPE.MY_LISTINGS,
    currentPage = 'LandingPage',
    companies,
    models,
  } = props;

  const { pub_category, pub_subcategory } = searchParams || {};

  const isSellAsIsSearch = searchPageType === SEARCH_PAGE_TYPE.SELL_AS_IS;
  const isPublishedSearch = searchPageType === SEARCH_PAGE_TYPE.PUBLISHED;

  const isCompanyView = location.search.includes(SEARCH_PAGE_COMPANY_VIEW_PARAM);

  const listingFieldsConfig = useMemo(() => {
    if (!config?.listing?.listingFields) return;

    return config.listing.listingFields.map(field => {
      const { key, marketplaceIds } = field;

      if (marketplaceIds && !marketplaceIds.includes(marketplaceId)) return null;

      const newField = { ...field };

      switch (key) {
        case 'category':
          newField.enumOptions = dimr.categories.map(category => ({
            option: category,
            label: `General.${camelCase(category)}`,
          }));

          break;

        case 'subcategory': {
          let subcategories = [];

          if (!pub_category)
            subcategories = getSuboptionsForOption(dimr.subcategories, pub_category);
          else {
            const splitCategories = pub_category.split(',');
            const splitSubcategories = pub_subcategory ? pub_subcategory.split(',') : [];

            // If we have already selected subcategories, but they are
            // not shown because of the selected categories, add them
            splitCategories.forEach(category => {
              subcategories = union(
                subcategories,
                getSuboptionsForOption(dimr.subcategories, category),
                splitSubcategories
              );
            });
          }

          newField.enumOptions = subcategories.map(category => ({
            option: category,
            label: `General.${camelCase(category)}`,
          }));

          break;
        }

        case 'itemType':
          newField.enumOptions = getSuboptionsForOption(dimr.itemTypes, pub_subcategory).map(
            type => ({
              option: type,
              label: `General.${camelCase(type)}`,
            })
          );
          break;

        case 'condition': {
          const isBrazilLocale = locale === LOCALE.BRAZIL;

          if (isBrazilLocale) return null;

          const isUSLocale = locale === LOCALE.UNITED_STATES;

          if (isUSLocale) {
            newField.enumOptions = [
              { option: LISTING_CONDITION.GRADE_A, label: 'General.gradeALongUS' },
              { option: LISTING_CONDITION.GRADE_B, label: 'General.gradeBLongUS' },
              { option: LISTING_CONDITION.GRADE_C, label: 'General.gradeCLongUS' },
              { option: LISTING_CONDITION.GRADE_D, label: 'General.gradeDLongUS' },
            ];
          } else {
            newField.enumOptions = newField.enumOptions.filter(
              e => e.option !== LISTING_CONDITION.GRADE_D
            );
          }

          break;
        }

        case 'country': {
          const countries = COUNTRIES_BY_LOCALE[locale] || [];

          if (countries.length < 1) {
            return null;
          }

          newField.enumOptions = countries.map(country => ({ option: country, label: country }));

          break;
        }

        // =========== 2ndlife fields ===========
        case 'status': {
          // These search pages will have only single status showing
          if (isSellAsIsSearch || isPublishedSearch) return null;

          const roleStatuses = getListingStatusForUserRole(currentUser);

          newField.enumOptions = newField.enumOptions.filter(o => roleStatuses.includes(o.option));

          break;
        }
        case 'contractEndOwnerCompanyId': {
          if (isCompanyView || isMarketplaceRespare(config)) return null;

          newField.enumOptions = companies?.map(company => ({
            option: company._id,
            label: company.name,
            isTranslatedLabel: true,
          }));
          newField.isOpenInitial = false;

          if (!newField.enumOptions?.length) return null;

          break;
        }

        case 'dimrModel': {
          if (isMarketplaceRespare(config)) return null;

          newField.enumOptions = models?.map(model => ({
            option: model,
            label: model,
            isTranslatedLabel: true,
          }));
          newField.isOpenInitial = false;

          if (!newField.enumOptions?.length) return null;

          break;
        }

        case 'inputGrading': {
          if (isMarketplaceRespare(config)) return null;

          newField.isOpenInitial = false;

          if (!newField.enumOptions?.length) return null;

          break;
        }

        case 'outputGrading': {
          if (isMarketplaceRespare(config)) return null;

          newField.isOpenInitial = false;

          if (!newField.enumOptions?.length) return null;

          break;
        }

        // ========= both 2ndlife and respare
        case 'locale': {
          // We don't show filter on respare, even though its sent via request
          if (marketplaceId === MARKETPLACE_ID.RESPARE) return null;

          const roleLocales = getListingLocalesForUserRole(currentUser);

          newField.enumOptions = newField.enumOptions.filter(o => roleLocales.includes(o.option));
          newField.isOpenInitial = false;

          if (!newField.enumOptions.length) return null;

          break;
        }

        default:
          break;
      }

      return newField;
    });
  }, [
    config,
    dimr,
    locale,
    pub_category,
    pub_subcategory,
    marketplaceId,
    currentUser,
    isSellAsIsSearch,
    isPublishedSearch,
    companies,
    isCompanyView,
    models,
  ]);

  const newConfig = useMemo(
    () => ({
      ...config,
      listing: { ...(config?.listing || {}), listingFields: listingFieldsConfig?.filter(Boolean) },
    }),
    [config, listingFieldsConfig]
  );

  return (
    <SearchPageComponent
      config={newConfig}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      routes={routes}
      {...props}
      currentPage={currentPage}
      searchPageType={searchPageType}
    />
  );
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,

    queryCompanyInProgress,
    queryCompanyError,
    company,
  } = state.SearchPage;
  const { isAuthenticated, authInfoLoaded } = state.auth;
  const { interfaceLang } = state.ui;
  const { queryCompaniesInProgress, queryCompaniesError, companies } = state.company;

  const models = [
    '115H',
    '415X',
    '430X',
    '430XH',
    '450X',
    '450XH',
    '435XAWD',
    '410iQ',
    '420iQ',
    '435iQAWD',
    '440iQ',
    '520 EPOS',
    '520H EPOS',
    '520',
    '535AWD EPOS',
    '520H',
    '550',
    '550H EPOS',
    '550H',
    '550 EPOS',
    '535AWD',
    '580L EPOS',
    'CEORA',
    '450X EPOS',
    '450XH EPOS',
  ];

  const { currentUser } = state.user;

  const listings = getListingsById(state, currentPageResultIds);

  return {
    currentUser,
    listings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    isAuthenticated,
    authInfoLoaded,
    dimr: state.dimr,
    interfaceLang,

    queryCompanyInProgress,
    queryCompanyError,
    company,
    queryCompaniesInProgress,
    queryCompaniesError,
    companies,
    models,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(connect(mapStateToProps, mapDispatchToProps))(EnhancedSearchPage);

export default SearchPage;
