import React from 'react';
import {
  ARCHIVED_TABS,
  LISTING_STATUS,
  PENDING_INTAKE_TABS,
  PENDING_REVIEW_TABS,
  SELL_AS_IS_TABS,
} from '../constants/listing';
import { FormattedMessage } from 'react-intl';
import { createResourceLocatorString } from './routes';
import css from '../containers/SearchPage/SearchPage.module.css';

export const getArchivedTabs = (selectedArchivedTab, setState, history, routes) => [
  {
    text: (
      <div className={css.tab}>
        <FormattedMessage id="General.bought" />
      </div>
    ),
    selected: selectedArchivedTab === ARCHIVED_TABS.BOUGHT,
    onClick: () => {
      const updatedQueryParams = {
        archived: true,
        meta_status: undefined,
        tab: ARCHIVED_TABS.BOUGHT,
      };

      setState({
        selectedArchivedTab: ARCHIVED_TABS.BOUGHT,
        currentQueryParams: updatedQueryParams,
      });

      history.replace(
        createResourceLocatorString('SearchPageMyListings', routes, {}, updatedQueryParams)
      );
    },
  },
  {
    text: (
      <div className={css.tab}>
        <FormattedMessage id="General.sold" />
      </div>
    ),
    selected: selectedArchivedTab === ARCHIVED_TABS.SOLD,
    onClick: () => {
      const updatedQueryParams = {
        archived: true,
        meta_status: LISTING_STATUS.SOLD,
        tab: ARCHIVED_TABS.SOLD,
      };

      setState({
        selectedArchivedTab: ARCHIVED_TABS.SOLD,
        currentQueryParams: updatedQueryParams,
      });

      history.replace(
        createResourceLocatorString('SearchPageMyListings', routes, {}, updatedQueryParams)
      );
    },
  },
  {
    text: (
      <div className={css.tab}>
        <FormattedMessage id="General.scrapped" />
      </div>
    ),
    selected: selectedArchivedTab === ARCHIVED_TABS.SCRAPPED,
    onClick: () => {
      const updatedQueryParams = {
        archived: true,
        meta_status: LISTING_STATUS.SCRAPPED,
        tab: ARCHIVED_TABS.SCRAPPED,
      };

      setState({
        selectedArchivedTab: ARCHIVED_TABS.SCRAPPED,
        currentQueryParams: updatedQueryParams,
      });

      history.replace(
        createResourceLocatorString('SearchPageMyListings', routes, {}, updatedQueryParams)
      );
    },
  },
  {
    text: (
      <div className={css.tab}>
        <FormattedMessage id="General.buyOutOrProlong" />
      </div>
    ),
    selected: selectedArchivedTab === ARCHIVED_TABS.BUY_OUT_OR_PROLONG,
    onClick: () => {
      const updatedQueryParams = {
        archived: true,
        meta_status: undefined,
        meta_leaseExpirationOption: 'customerBuyOut,prolongLeasing',
        tab: ARCHIVED_TABS.BUY_OUT_OR_PROLONG,
      };

      setState({
        selectedArchivedTab: ARCHIVED_TABS.BUY_OUT_OR_PROLONG,
        currentQueryParams: updatedQueryParams,
      });

      history.replace(
        createResourceLocatorString('SearchPageMyListings', routes, {}, updatedQueryParams)
      );
    },
  },
];

export const getPendingIntakeTabs = (
  selectedPendingIntakeTab,
  locationSearch,
  setState,
  history,
  routes
) => [
  {
    text: (
      <div className={css.tab}>
        <FormattedMessage id="SearchPage.all" />
      </div>
    ),
    selected: selectedPendingIntakeTab === PENDING_INTAKE_TABS.ALL,
    onClick: () => {
      const updatedQueryParams = {
        meta_status: LISTING_STATUS.PENDING_INTAKE,
        pendingIntakeState: locationSearch?.pendingIntakeState,
        tab: PENDING_INTAKE_TABS.ALL,
      };

      setState({
        selectedPendingIntakeTab: PENDING_INTAKE_TABS.ALL,
        currentQueryParams: updatedQueryParams,
      });

      history.replace(
        createResourceLocatorString('SearchPageMyListings', routes, {}, updatedQueryParams)
      );
    },
  },
  {
    text: (
      <div className={css.tab}>
        <FormattedMessage id="SearchPage.customerNotContacted" />
      </div>
    ),
    selected: selectedPendingIntakeTab === PENDING_INTAKE_TABS.CUSTOMER_NOT_CONTACTED,
    onClick: () => {
      const updatedQueryParams = {
        meta_status: LISTING_STATUS.PENDING_INTAKE,
        meta_hasContactedLeasingUser: false,
        pendingIntakeState: locationSearch?.pendingIntakeState,
        tab: PENDING_INTAKE_TABS.CUSTOMER_NOT_CONTACTED,
      };

      setState({
        selectedPendingIntakeTab: PENDING_INTAKE_TABS.CUSTOMER_NOT_CONTACTED,
        currentQueryParams: updatedQueryParams,
      });

      history.replace(
        createResourceLocatorString('SearchPageMyListings', routes, {}, updatedQueryParams)
      );
    },
  },
  {
    text: (
      <div className={css.tab}>
        <FormattedMessage id="SearchPage.customerContacted" />
      </div>
    ),
    selected: selectedPendingIntakeTab === PENDING_INTAKE_TABS.CUSTOMER_CONTACTED,
    onClick: () => {
      const updatedQueryParams = {
        meta_status: LISTING_STATUS.PENDING_INTAKE,
        meta_hasContactedLeasingUser: true,
        pendingIntakeState: locationSearch?.pendingIntakeState,
        tab: PENDING_INTAKE_TABS.CUSTOMER_CONTACTED,
      };

      setState({
        selectedPendingIntakeTab: PENDING_INTAKE_TABS.CUSTOMER_CONTACTED,
        currentQueryParams: updatedQueryParams,
      });

      history.replace(
        createResourceLocatorString('SearchPageMyListings', routes, {}, updatedQueryParams)
      );
    },
  },
  {
    text: (
      <div className={css.tab}>
        <FormattedMessage id="SearchPage.customerResponded" />
      </div>
    ),
    selected: selectedPendingIntakeTab === PENDING_INTAKE_TABS.CUSTOMER_RESPONDED,
    onClick: () => {
      const updatedQueryParams = {
        meta_status: LISTING_STATUS.PENDING_INTAKE,
        meta_leaseExpirationOption: 'nothing,newLeasingOffering,customerBuyOut,prolongLeasing',
        pendingIntakeState: locationSearch?.pendingIntakeState,
        tab: PENDING_INTAKE_TABS.CUSTOMER_RESPONDED,
      };

      setState({
        selectedPendingIntakeTab: PENDING_INTAKE_TABS.CUSTOMER_RESPONDED,
        currentQueryParams: updatedQueryParams,
      });

      history.replace(
        createResourceLocatorString('SearchPageMyListings', routes, {}, updatedQueryParams)
      );
    },
  },
];

export const getPendingReviewTabs = (
  selectedPendingReviewTab,
  setState,
  history,
  routes,
  currentUser
) => [
  {
    text: (
      <div className={css.tab}>
        <FormattedMessage id="SearchPage.all" />
      </div>
    ),
    selected: selectedPendingReviewTab === PENDING_REVIEW_TABS.ALL,
    onClick: () => {
      const updatedQueryParams = {
        meta_status: LISTING_STATUS.PENDING_REVIEW,
        tab: PENDING_REVIEW_TABS.ALL,
      };

      setState({
        selectedPendingReviewTab: PENDING_REVIEW_TABS.ALL,
        currentQueryParams: updatedQueryParams,
      });

      history.replace(
        createResourceLocatorString('SearchPageMyListings', routes, {}, updatedQueryParams)
      );
    },
  },
  {
    text: (
      <div className={css.tab}>
        <FormattedMessage id="SearchPage.ownedByMe" />
      </div>
    ),
    selected: selectedPendingReviewTab === PENDING_REVIEW_TABS.OWNED_BY_ME,
    onClick: () => {
      const updatedQueryParams = {
        meta_status: LISTING_STATUS.PENDING_REVIEW,
        meta_contractEndOwnerCompanyId: currentUser?.attributes?.profile?.publicData?.companyId,
        tab: PENDING_REVIEW_TABS.OWNED_BY_ME,
      };

      setState({
        selectedPendingReviewTab: PENDING_REVIEW_TABS.OWNED_BY_ME,
        currentQueryParams: updatedQueryParams,
      });

      history.replace(
        createResourceLocatorString('SearchPageMyListings', routes, {}, updatedQueryParams)
      );
    },
  },
  {
    text: (
      <div className={css.tab}>
        <FormattedMessage id="SearchPage.ownedByHusqvarna" />
      </div>
    ),
    selected: selectedPendingReviewTab === PENDING_REVIEW_TABS.OWNED_BY_HUSQVARNA,
    onClick: () => {
      const updatedQueryParams = {
        meta_status: LISTING_STATUS.PENDING_REVIEW,
        meta_contractEndOwnerCompanyName: 'Husqvarna',
        tab: PENDING_REVIEW_TABS.OWNED_BY_HUSQVARNA,
      };

      setState({
        selectedPendingReviewTab: PENDING_REVIEW_TABS.OWNED_BY_HUSQVARNA,
        currentQueryParams: updatedQueryParams,
      });

      history.replace(
        createResourceLocatorString('SearchPageMyListings', routes, {}, updatedQueryParams)
      );
    },
  },
];

export const getForSaleTabs = (selectedForSaleTab, setState, history, routes) => [
  {
    text: (
      <div className={css.tab}>
        <FormattedMessage id="General.published" />
      </div>
    ),
    selected: selectedForSaleTab === LISTING_STATUS.PUBLISHED,
    onClick: () => {
      const updatedQueryParams = {
        meta_status: LISTING_STATUS.PUBLISHED,
        forSale: true,
        tab: LISTING_STATUS.PUBLISHED,
      };

      setState({
        selectedForSaleTab: LISTING_STATUS.PUBLISHED,
        currentQueryParams: updatedQueryParams,
      });

      history.replace(
        createResourceLocatorString('SearchPageMyListings', routes, {}, updatedQueryParams)
      );
    },
  },
  {
    text: (
      <div className={css.tab}>
        <FormattedMessage id="General.sold" />
      </div>
    ),
    selected: selectedForSaleTab === LISTING_STATUS.SOLD,
    onClick: () => {
      const updatedQueryParams = {
        meta_status: LISTING_STATUS.SOLD,
        forSale: true,
        tab: LISTING_STATUS.SOLD,
      };

      setState({
        selectedForSaleTab: LISTING_STATUS.SOLD,
        currentQueryParams: updatedQueryParams,
      });

      history.replace(
        createResourceLocatorString('SearchPageMyListings', routes, {}, updatedQueryParams)
      );
    },
  },
];

export const getSellAsIsTabs = (selectedSellAsIsTab, locationSearch, setState, history, routes) => [
  {
    id: 'forSale',
    text: (
      <div className={css.tab}>
        <FormattedMessage id="General.forSale" />
      </div>
    ),
    selected: selectedSellAsIsTab === SELL_AS_IS_TABS.FOR_SALE,
    onClick: () => {
      const updatedQueryParams = {
        asIs: true,
        meta_status: LISTING_STATUS.SELL_AS_IS,
        sellAsIsState: locationSearch?.sellAsIsState,
        tab: SELL_AS_IS_TABS.FOR_SALE,
        owned: false,
      };

      setState({
        selectedSellAsIsTab: SELL_AS_IS_TABS.FOR_SALE,
        currentQueryParams: updatedQueryParams,
      });
      history.replace(
        createResourceLocatorString('SearchPageMyListings', routes, {}, updatedQueryParams)
      );
    },
  },
  {
    id: 'pendingSold',
    text: (
      <div className={css.tab}>
        <FormattedMessage id="General.pendingSold" />
      </div>
    ),
    selected: selectedSellAsIsTab === SELL_AS_IS_TABS.PENDING_SOLD,
    onClick: () => {
      const updatedQueryParams = {
        asIs: true,
        meta_status: LISTING_STATUS.SOLD,
        meta_isBuyerConfirmPending: true,
        sellAsIsState: locationSearch?.sellAsIsState,
        tab: SELL_AS_IS_TABS.PENDING_SOLD,
      };

      setState({
        selectedSellAsIsTab: SELL_AS_IS_TABS.PENDING_SOLD,
        currentQueryParams: updatedQueryParams,
      });

      history.replace(
        createResourceLocatorString('SearchPageMyListings', routes, {}, updatedQueryParams)
      );
    },
  },
  {
    id: 'bought',
    text: (
      <div className={css.tab}>
        <FormattedMessage id="General.bought" />
      </div>
    ),
    selected: selectedSellAsIsTab === SELL_AS_IS_TABS.BOUGHT,
    onClick: () => {
      const updatedQueryParams = {
        asIs: true,
        meta_status: undefined,
        sellAsIsState: locationSearch?.sellAsIsState,
        tab: SELL_AS_IS_TABS.BOUGHT,
      };

      setState({
        selectedSellAsIsTab: SELL_AS_IS_TABS.BOUGHT,
        currentQueryParams: updatedQueryParams,
      });
      history.replace(
        createResourceLocatorString('SearchPageMyListings', routes, {}, updatedQueryParams)
      );
    },
  },
  {
    id: 'sold',
    text: (
      <div className={css.tab}>
        <FormattedMessage id="General.sold" />
      </div>
    ),
    selected: selectedSellAsIsTab === SELL_AS_IS_TABS.SOLD,
    onClick: () => {
      const updatedQueryParams = {
        asIs: true,
        meta_status: LISTING_STATUS.SOLD,
        meta_isBuyerConfirmPending: false,
        sellAsIsState: locationSearch?.sellAsIsState,
        tab: SELL_AS_IS_TABS.SOLD,
      };

      setState({
        selectedSellAsIsTab: SELL_AS_IS_TABS.SOLD,
        currentQueryParams: updatedQueryParams,
      });
      history.replace(
        createResourceLocatorString('SearchPageMyListings', routes, {}, updatedQueryParams)
      );
    },
  },
  {
    id: 'shipped',
    text: (
      <div className={css.tab}>
        <FormattedMessage id="General.shipped" />
      </div>
    ),
    selected: selectedSellAsIsTab === SELL_AS_IS_TABS.SHIPPED,
    onClick: () => {
      const updatedQueryParams = {
        asIs: true,
        meta_status: LISTING_STATUS.SOLD,
        meta_shipped: true,
        sellAsIsState: locationSearch?.sellAsIsState,
        tab: SELL_AS_IS_TABS.SHIPPED,
      };

      setState({
        selectedSellAsIsTab: SELL_AS_IS_TABS.SHIPPED,
        currentQueryParams: updatedQueryParams,
      });

      history.replace(
        createResourceLocatorString('SearchPageMyListings', routes, {}, updatedQueryParams)
      );
    },
  },
  {
    id: 'received',
    text: (
      <div className={css.tab}>
        <FormattedMessage id="General.received" />
      </div>
    ),
    selected: selectedSellAsIsTab === SELL_AS_IS_TABS.RECEIVED,
    onClick: () => {
      const updatedQueryParams = {
        asIs: true,
        meta_status: LISTING_STATUS.PENDING_REVIEW,
        sellAsIsState: locationSearch?.sellAsIsState,
        tab: SELL_AS_IS_TABS.RECEIVED,
      };

      setState({
        selectedSellAsIsTab: SELL_AS_IS_TABS.RECEIVED,
        currentQueryParams: updatedQueryParams,
      });

      history.replace(
        createResourceLocatorString('SearchPageMyListings', routes, {}, updatedQueryParams)
      );
    },
  },
];
