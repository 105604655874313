import React, { Component } from 'react';
import { arrayOf, bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../../util/reactIntl';
import IconPlus from '../IconPlus/IconPlus';
import { SEARCH_PAGE_TYPE } from '../../../util/marketplace';

import css from './SelectSingleFilterPlain.module.css';
import {
  ARCHIVED_TABS,
  LISTING_STATUS,
  PENDING_INTAKE_TABS,
  SELL_AS_IS_TABS,
} from '../../../constants/listing';

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

class SelectSingleFilterPlain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: this.props.isOpenInitial ?? true,
    };
    this.selectOption = this.selectOption.bind(this);
    this.toggleIsOpen = this.toggleIsOpen.bind(this);
  }

  selectOption(option, subFilter, e) {
    const { queryParamNames, onSelect, currentUser, onOptionSelect } = this.props;
    const queryParamName = getQueryParamName(queryParamNames);

    if (onOptionSelect) {
      onOptionSelect();
    }

    if (option === 'archived') {
      onSelect({
        archived: true,
        [queryParamName]: undefined,
        meta_isBuyerConfirmPending: undefined,
        meta_buyerCompanyId: undefined,
        meta_hasContactedLeasingUser: undefined,
        meta_leaseExpirationOption: undefined,
        meta_contractEndOwnerCompanyId: undefined,
        meta_contractEndOwnerCompanyName: undefined,
        forSale: undefined,
        asIs: undefined,
        pendingIntakeState: undefined,
        sellAsIsState: undefined,
        meta_shipped: undefined,
        tab: ARCHIVED_TABS.BOUGHT,
        owned: undefined,
      });
    } else if (option === 'sellAsIs') {
      onSelect({
        [queryParamName]: option,
        forSale: undefined,
        archived: undefined,
        meta_isBuyerConfirmPending: undefined,
        meta_buyerCompanyId: undefined,
        meta_hasContactedLeasingUser: undefined,
        meta_leaseExpirationOption: undefined,
        meta_contractEndOwnerCompanyId: undefined,
        meta_contractEndOwnerCompanyName: undefined,
        sellAsIsState: subFilter || 'buying',
        asIs: true,
        pendingIntakeState: undefined,
        tab: SELL_AS_IS_TABS.FOR_SALE,
        owned: subFilter === 'buying' || !subFilter ? false : undefined,
      });
    } else if (option === 'published') {
      onSelect({
        [queryParamName]: option,
        forSale: true,
        archived: undefined,
        meta_isBuyerConfirmPending: undefined,
        meta_buyerCompanyId: undefined,
        meta_hasContactedLeasingUser: undefined,
        meta_leaseExpirationOption: undefined,
        meta_contractEndOwnerCompanyId: undefined,
        meta_contractEndOwnerCompanyName: undefined,
        asIs: undefined,
        pendingIntakeState: undefined,
        sellAsIsState: undefined,
        meta_shipped: undefined,
        tab: LISTING_STATUS.PUBLISHED,
        owned: undefined,
      });
    } else {
      onSelect({
        [queryParamName]: option,
        pendingIntakeState: option === 'pendingIntake' ? subFilter : undefined,
        archived: undefined,
        meta_isBuyerConfirmPending: undefined,
        meta_buyerCompanyId: undefined,
        meta_hasContactedLeasingUser: undefined,
        meta_leaseExpirationOption: undefined,
        meta_contractEndOwnerCompanyId: undefined,
        meta_contractEndOwnerCompanyName: undefined,
        forSale: undefined,
        asIs: undefined,
        sellAsIsState: undefined,
        meta_shipped: undefined,
        tab: undefined,
        owned: undefined,
      });
    }

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  toggleIsOpen() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const {
      rootClassName,
      className,
      label,
      options,
      queryParamNames,
      initialValues,
      twoColumns,
      useBorder,
      useHighlight,
      intl,
      location,
      searchPageType,
    } = this.props;

    const isMyListingsPage = searchPageType
      ? searchPageType === SEARCH_PAGE_TYPE.MY_LISTINGS
      : location?.pathname.includes('my-listings') ||
        location?.pathname === '/' ||
        location.pathname === '/en/' ||
        location.pathname === '/no/';

    // display 'archived' and 'pending sold' options only if it's the 'my-listings' page
    const filteredOptions =
      label === 'General.leaseStatus'
        ? options.filter(option => {
            if (option.key === 'archived' || option.key === 'pendingSold') {
              return isMyListingsPage;
            }
            return true;
          })
        : options;

    const queryParamName = getQueryParamName(queryParamNames);
    const initialValue = location?.search.includes('archived')
      ? 'archived'
      : location?.search.includes('forSale=true')
      ? 'published'
      : location?.search.includes('asIs=true')
      ? 'sellAsIs'
      : initialValues && initialValues[queryParamName]
      ? initialValues[queryParamName]
      : null;

    const labelClass = initialValue ? css.labelSelected : css.label;

    const optionsContainerClass = classNames({
      [css.optionsContainerOpen]: this.state.isOpen,
      [css.optionsContainerClosed]: !this.state.isOpen,
      [css.twoColumns]: twoColumns,
    });

    let classes = classNames(css[rootClassName], css.root, className);

    return (
      <div className={classes}>
        <div className={css.filterHeader}>
          <button className={css.labelButton} onClick={this.toggleIsOpen}>
            <span className={css.labelButtonContent}>
              <span className={labelClass}>{intl.formatMessage({ id: label })}</span>
              <span className={css.openSign}>
                <IconPlus isOpen={this.state.isOpen} isSelected={!!initialValue} />
              </span>
            </span>
          </button>
        </div>
        <div className={optionsContainerClass}>
          <div>
            {filteredOptions.map(option => {
              // check if this option is selected
              const selected = initialValue === option.key;

              const optionClass = classNames(css.option, {
                [css.optionHighlight]: selected && useHighlight,
              });
              // menu item selected bullet or border class
              const optionBorderClass = useBorder
                ? classNames({
                    [css.optionBorderSelected]: selected,
                    [css.optionBorder]: !selected,
                  })
                : null;

              const initialSubFilterValue =
                initialValues && initialValues['pendingIntakeState']
                  ? initialValues['pendingIntakeState']
                  : initialValues && initialValues['sellAsIsState']
                  ? initialValues['sellAsIsState']
                  : null;

              return (
                <span key={option.key}>
                  <button
                    className={classNames(optionClass, css.mainOption)}
                    onClick={() => this.selectOption(option.key)}
                  >
                    {useBorder ? <span className={optionBorderClass} /> : null}
                    {option.isTranslatedLabel
                      ? option.label
                      : intl.formatMessage({ id: option.label })}
                  </button>

                  {/* if there are subFilters, display them */}
                  {option?.subFilters && (
                    <span className={css.subFilters}>
                      {option?.subFilters.map(subFilter => (
                        <button
                          className={classNames(css.option, {
                            [css.optionHighlight]: initialSubFilterValue === subFilter.value,
                          })}
                          key={subFilter.value}
                          onClick={() => this.selectOption(option.key, subFilter.value)}
                        >
                          {intl.formatMessage({ id: subFilter.label })}
                        </button>
                      ))}
                    </span>
                  )}
                </span>
              );
            })}
          </div>

          <button className={css.clearButton} onClick={e => this.selectOption(null, null, e)}>
            <FormattedMessage id={'SelectSingleFilter.plainClear'} />
          </button>
        </div>
      </div>
    );
  }
}

SelectSingleFilterPlain.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  twoColumns: false,
  useHighlight: true,
  useBorder: false,
};

SelectSingleFilterPlain.propTypes = {
  rootClassName: string,
  className: string,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSelect: func.isRequired,

  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  initialValues: object,
  twoColumns: bool,
  useHighlight: bool,
  useBorder: bool,
};

export default SelectSingleFilterPlain;
