import React from 'react';

import { SCHEMA_TYPE_ENUM, SCHEMA_TYPE_MULTI_ENUM, SCHEMA_TYPE_RADIO } from '../../util/types';
import { constructQueryParamName } from './SearchPage.shared';
import SelectSingleFilter from './SelectSingleFilter/SelectSingleFilter';
import SelectMultipleFilter from './SelectMultipleFilter/SelectMultipleFilter';
import BookingDateRangeFilter from './BookingDateRangeFilter/BookingDateRangeFilter';
import KeywordFilter from './KeywordFilter/KeywordFilter';
import PriceFilter from './PriceFilter/PriceFilter';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

// Helper: get enumOptions in a format that works as query parameter
const createFilterOptions = options =>
  options.map(o => ({
    key: `${o.option}`,
    label: o.label,
    isTranslatedLabel: o.isTranslatedLabel,
    subFilters: o?.subFilters,
  }));

/**
 * FilterComponent is used to map configured filter types
 * to actual filter components
 */
const FilterComponent = props => {
  const {
    idPrefix,
    config,
    urlQueryParams,
    initialValues,
    getHandleChangedValueFn,
    marketplaceCurrency,
    intl,
    rootClassName,
    is2ndLife,
    searchPageType,
    ...rest
  } = props;

  // Note: config can be either
  // - listingFields config or
  // - default filter config
  // They both have 'key' and 'schemaType' included.
  const { key, schemaType } = config;
  const { liveEdit, showAsPopup } = rest;

  const useHistoryPush = liveEdit || showAsPopup;
  const prefix = idPrefix || 'LandingPage';
  const componentId = `${prefix}.${key.toLowerCase()}`;
  const name = key.replace(/\s+/g, '-').toLowerCase();
  const location = useLocation();

  // Default filters: price, keywords, dates
  switch (key) {
    case 'price': {
      if (is2ndLife) return null;
      const { min, max, step } = config;
      return (
        <PriceFilter
          id={componentId}
          label={intl.formatMessage({ id: 'FilterComponent.priceLabel' })}
          queryParamNames={[key]}
          initialValues={initialValues([key], liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          min={min}
          max={max}
          step={step}
          marketplaceCurrency={marketplaceCurrency}
          {...rest}
        />
      );
    }
    case 'residualValue': {
      const { priceValues } = config;
      const { min, max, step } = priceValues;
      const queryParamNames = [constructQueryParamName(key, config?.scope)];

      return (
        <PriceFilter
          id={componentId}
          label={intl.formatMessage({ id: 'General.price' })}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          min={min}
          max={max}
          step={step}
          marketplaceCurrency={marketplaceCurrency}
          is2ndLife={is2ndLife}
          {...rest}
        />
      );
    }
    case 'keywords':
      return (
        <KeywordFilter
          id={componentId}
          label={intl.formatMessage({ id: 'FilterComponent.keywordsLabel' })}
          name={name}
          queryParamNames={[key]}
          initialValues={initialValues([key], liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          {...rest}
        />
      );
    case 'dates': {
      const { dateRangeMode } = config;
      const isNightlyMode = dateRangeMode === 'night';
      return (
        <BookingDateRangeFilter
          id={componentId}
          label={intl.formatMessage({ id: 'FilterComponent.datesLabel' })}
          queryParamNames={[key]}
          initialValues={initialValues([key], liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          minimumNights={isNightlyMode ? 1 : 0}
          {...rest}
        />
      );
    }

    default:
      break;
  }

  // Custom extended data filters
  switch (schemaType) {
    case SCHEMA_TYPE_RADIO:
    case SCHEMA_TYPE_ENUM: {
      const { scope, enumOptions, isOpenInitial, filterConfig = {} } = config;
      const queryParamNames = [constructQueryParamName(key, scope)];

      return filterConfig.filterType === 'SelectSingleFilter' ? (
        <SelectSingleFilter
          id={componentId}
          label={filterConfig.label}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSelect={getHandleChangedValueFn(useHistoryPush)}
          options={createFilterOptions(enumOptions)}
          intl={intl}
          location={location}
          isOpenInitial={isOpenInitial}
          rootClassName={rootClassName}
          onOptionSelect={props?.onOptionSelect}
          searchPageType={searchPageType}
          {...rest}
        />
      ) : (
        <SelectMultipleFilter
          id={componentId}
          label={filterConfig.label}
          name={name}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          options={createFilterOptions(enumOptions)}
          schemaType={schemaType}
          intl={intl}
          {...rest}
        />
      );
    }
    case SCHEMA_TYPE_MULTI_ENUM: {
      const { scope, enumOptions, filterConfig = {} } = config;
      const { label, searchMode } = filterConfig;
      const queryParamNames = [constructQueryParamName(key, scope)];
      return (
        <SelectMultipleFilter
          id={componentId}
          label={label}
          name={name}
          queryParamNames={queryParamNames}
          initialValues={initialValues(queryParamNames, liveEdit)}
          onSubmit={getHandleChangedValueFn(useHistoryPush)}
          options={createFilterOptions(enumOptions)}
          schemaType={schemaType}
          searchMode={searchMode}
          intl={intl}
          {...rest}
        />
      );
    }
    default:
      return null;
  }
};

export default FilterComponent;
